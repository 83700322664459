import React, { useState, useMemo, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomePage from "./components/home/HomePage";
import SupportPage from "./components/chat/ChatWrapper";
import ReceiptPreviewPage from "./components/receipt/ReceiptPreview";
import MobiswiftScan from "./components/scanner/MobiswiftScanner";
import MobInvoice from "./components/receipt/MobInvoice";
import MomoPaymentSuccess from "./components/services/MomoPaymentSuccess";

//import SigninPage from "./components/user/SigninPage";
const SigninPage = React.lazy(() => import("./components/user/SigninPage"));
//import SignupPage from "./components/user/SignupPage";
const SignupPage = React.lazy(() => import("./components/user/SignupPage"));

//import MobishopHome from "./components/marketplace/MobishopHome";
const MobishopHome = React.lazy(() =>
  import("./components/marketplace/MobishopHome")
);

//import MobishopCheckoutPay from "./components/marketplace/MobishopCheckoutPay";
const MobishopCheckoutPay = React.lazy(() =>
  import("./components/marketplace/MobishopCheckoutPay")
);

//MobishopCheckoutCard
//import MobishopCheckoutCard from "./components/marketplace/MobishopCheckoutCard";
const MobishopCheckoutCard = React.lazy(() =>
  import("./components/marketplace/MobishopCheckoutCard")
);

//import MobishopCheckout from "./components/marketplace/MobishopCheckout";
const MobishopCheckout = React.lazy(() =>
  import("./components/marketplace/MobishopCheckout")
);

//import MobiCoupons from "./components/marketplace/MobiCoupons";
const MobiCouponPage = React.lazy(() =>
  import("./components/marketplace/MobiCouponPage")
);

//import BrokerSignUpPage from "./components/broker/BrokerSignUpPage";
const BrokerSignUpPage = React.lazy(() =>
  import("./components/broker/BrokerSignUpPage")
);

//import BrokerPage from "./components/broker/BrokerPage";
const BrokerPage = React.lazy(() => import("./components/broker/BrokerPage"));

//import Feedback from "./components/feedback/Feedback";
const FeedbackPage = React.lazy(() => import("./components/feedback/Feedback"));

//import Mservices from "./components/services/Mservices";
const Mservices = React.lazy(() => import("./components/services/Mservices"));

//MobishopCart
//import MobishopCart from "./components/marketplace/MobishopCart";
const MobishopCart = React.lazy(() =>
  import("./components/marketplace/MobishopCart")
);

//import MobiCoupons from "./components/marketplace/MobiCoupons";
const MobiCoupons = React.lazy(() =>
  import("./components/marketplace/MobiCoupons")
);

//import MobiShop from "./components/marketplace/MobiShop";
const MobiShop = React.lazy(() => import("./components/marketplace/MobiShop"));

//import MobiTv from "./components/marketplace/MobiTv";
const MobiTv = React.lazy(() => import("./components/marketplace/MobiTv"));

//import MobiYellow from "./components/marketplace/MobiYellow";
const MobiYellow = React.lazy(() =>
  import("./components/marketplace/MobiYellow")
);

//import MobiYellowPage from "./components/marketplace/MobiYellowPage";
const MobiYellowPage = React.lazy(() =>
  import("./components/marketplace/MobiYellowPage")
);

//import AccessBankPage from "./components/cashless/AccessBankPage";
const AccessBankPage = React.lazy(() =>
  import("./components/cashless/AccessBankPage")
);

//import BprBankPage from "./components/cashless/BprBankPage";
const BprBankPage = React.lazy(() =>
  import("./components/cashless/BprBankPage")
);

//import CogebankPage from "./components/cashless/CogebankPage";
const CogebankPage = React.lazy(() =>
  import("./components/cashless/CogebankPage")
);

//import EquityBankPage from "./components/cashless/EquityBankPage";
const EquityBankPage = React.lazy(() =>
  import("./components/cashless/EquityBankPage")
);

//import GtBankPage from "./components/cashless/GtBankPage";
const GtBankPage = React.lazy(() => import("./components/cashless/GtBankPage"));

//import ImBankPage from "./components/cashless/ImBankPage";
const ImBankPage = React.lazy(() => import("./components/cashless/ImBankPage"));

//import MobicashLicense from "./components/footer/MobicashLicense";
const MobicashLicense = React.lazy(() =>
  import("./components/footer/MobicashLicense")
);

//import WhatsappMessenger from './components/messenger/WhatsappMessenger';
const WhatsappMessenger = React.lazy(() =>
  import("./components/messenger/WhatsappMessenger")
);

//import MerchantInformation from './components/scanner/MerchantInformation';
const MerchantInformation = React.lazy(() =>
  import("./components/scanner/MerchantInformation")
);

//import MerchantPaymentSuccess from './components/scanner/MerchantPaymentSuccess';
const MerchantPaymentSuccess = React.lazy(() =>
  import("./components/scanner/MerchantPaymentSuccess")
);

//import MarketplacePage from "./components/marketplace/MarketplacePage";
const MarketplacePage = React.lazy(() =>
  import("./components/marketplace/MarketplacePage")
);

//import AboutPage from "./components/about/About";
const AboutPage = React.lazy(() => import("./components/about/About"));

//import TermsPage from "./components/business/TermsPage";
const TermsPage = React.lazy(() => import("./components/business/TermsPage"));

//import TarrifPage from "./components/business/TarrifPage";
const TarrifPage = React.lazy(() => import("./components/business/TarrifPage"));

//import PolicyPage from "./components/business/PolicyPage";
const PolicyPage = React.lazy(() => import("./components/business/PolicyPage"));

//import FraudPage from "./components/business/FraudPage";
const FraudPage = React.lazy(() => import("./components/business/FraudPage"));

//import businessPage from "./components/business/BusinessPage";
const BusinessPage = React.lazy(() =>
  import("./components/business/BusinessPage")
);
//import ScanPay from "./components/scanner/ScanPay";
const ScanPay = React.lazy(() => import("./components/scanner/ScanPay"));
//import MobiTokenPage from "./components/mobitoken/MobiTokens";
const MobiTokenPage = React.lazy(() =>
  import("./components/mobitoken/MobiTokens")
);
//import KashiresiPage from "./components/cashless/KashiresiPage";
const KashiresiPage = React.lazy(() =>
  import("./components/cashless/KashiresiPage")
);
//import InternetBankingPage from "./components/cashless/InternetBanking";
const InternetBankingPage = React.lazy(() =>
  import("./components/cashless/InternetBanking")
);
//import DebitPayPage from "./components/debitpay/DebitPayPage";
const DebitPayPage = React.lazy(() =>
  import("./components/debitpay/DebitPayPage")
);
//import CbhiPage from "./components/services/cbhi/CbhiMenu";
const CbhiPage = React.lazy(() =>
  import("./components/services/cbhi/CbhiMenu")
);
//import ElectricityPage from "./components/services/electricity/ElectricityMenu";
const ElectricityPage = React.lazy(() =>
  import("./components/services/electricity/ElectricityMenu")
);
//import RraMenu from "./components/services/rra/RraMenu";
const RraMenu = React.lazy(() => import("./components/services/rra/RraMenu"));
//import LtssMenu from "./components/services/ltss/LtssMenu";
const LtssMenu = React.lazy(() =>
  import("./components/services/ltss/LtssMenu")
);
//import RnitMenu from "./components/services/rnit/RnitMenu";
const RnitMenu = React.lazy(() =>
  import("./components/services/rnit/RnitMenu")
);
//import MtnMenu from "./components/services/mtn/MtnMenu";
const MtnMenu = React.lazy(() => import("./components/services/mtn/MtnMenu"));
//import DisposableCard from "./components/mobitoken/DisposableCard";
const DisposableCard = React.lazy(() =>
  import("./components/mobitoken/DisposableCard")
);
//import FixPremiumCard from "./components/mobitoken/FixPremiumCard";
const FixPremiumCard = React.lazy(() =>
  import("./components/mobitoken/FixPremiumCard")
);
//import InotiPayCard from "./components/mobitoken/InotiPayCard";
const InotiPayCard = React.lazy(() =>
  import("./components/mobitoken/InotiPayCard")
);
//import InotiSendCard from "./components/mobitoken/InotiSendCard";
const InotiSendCard = React.lazy(() =>
  import("./components/mobitoken/InotiSendCard")
);
//import VcardPage from "./components/mobitoken/VcardPage";
const VcardPage = React.lazy(() => import("./components/mobitoken/VcardPage"));
//import VchequePage from "./components/mobitoken/VchequePage";
const VchequePage = React.lazy(() =>
  import("./components/mobitoken/VchequePage")
);
//import DebitPay from "./components/debitpay/DebitPay";
const DebitPay = React.lazy(() => import("./components/debitpay/DebitPay"));
//import Marketplace from './components/marketplace/Marketplace';
const Marketplace = React.lazy(() =>
  import("./components/marketplace/Marketplace")
);
//import SupportCenter from './components/support/SupportCenter';
const SupportCenter = React.lazy(() =>
  import("./components/support/SupportCenter")
);
//import Cashless from './components/cashless/Cashless';
const Cashless = React.lazy(() => import("./components/cashless/Cashless"));
//import Lifestyle from './components/lifestyle/Lifestyle';
const Lifestyle = React.lazy(() => import("./components/lifestyle/Lifestyle"));
//import Splash from './components/Splash';
//import VisitRwanda from './components/lifestyle/VisitRwanda';
const VisitRwanda = React.lazy(() =>
  import("./components/lifestyle/VisitRwanda")
);
//import Rwandair from './components/lifestyle/Rwandair';
const Rwandair = React.lazy(() => import("./components/lifestyle/Rwandair"));
//import CleoHotel from './components/lifestyle/CleoHotel';
const CleoHotel = React.lazy(() => import("./components/lifestyle/CleoHotel"));
//import Akagera from './components/lifestyle/Akagera';
const Akagera = React.lazy(() => import("./components/lifestyle/Akagera"));
//import AlertPage from './components/alert/AlertPage';
const AlertPage = React.lazy(() => import("./components/alert/AlertPage"));

//import RiaPage from './components/ria/RiaMenu';
const RiaPage = React.lazy(() => import("./components/services/ria/RiaMenu"));

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />}>
            {" "}
          </Route>
          <Route path="/support-center" element={<SupportPage />}>
            {" "}
          </Route>
          <Route path="/receipt-preview" element={<ReceiptPreviewPage />}>
            {" "}
          </Route>
          <Route path="/qrcode-payment" element={<MobiswiftScan />}></Route>

          <Route
            path="/signin"
            element={
              <Suspense fallback={<>...</>}>
                <SigninPage />
              </Suspense>
            }
          ></Route>
          <Route
            path="/ria-order"
            element={
              <Suspense fallback={<>...</>}>
                <RiaPage />
              </Suspense>
            }
          ></Route>
          <Route
            path="/signup"
            element={
              <Suspense fallback={<>...</>}>
                <SignupPage />
              </Suspense>
            }
          ></Route>

          <Route path="/view-receipt" element={<MobInvoice />}></Route>
          <Route
            path="/payment-status"
            element={<MomoPaymentSuccess />}
          ></Route>

          <Route
            path="/checkout-mobitoken"
            element={
              <Suspense fallback={<>...</>}>
                <MobishopCheckoutCard />
              </Suspense>
            }
          ></Route>
          <Route
            path="/payment-method"
            element={
              <Suspense fallback={<>...</>}>
                <MobishopCheckoutPay />
              </Suspense>
            }
          ></Route>

          <Route
            path="/mobi-checkout"
            element={
              <Suspense fallback={<>...</>}>
                <MobishopCheckout />
              </Suspense>
            }
          ></Route>
          <Route
            path="/mobi-cart"
            element={
              <Suspense fallback={<>...</>}>
                <MobishopCart />
              </Suspense>
            }
          ></Route>
          <Route
            path="/mobishoper"
            element={
              <Suspense fallback={<>...</>}>
                <MobishopHome />
              </Suspense>
            }
          ></Route>

          <Route
            path="/coupons"
            element={
              <Suspense fallback={<>...</>}>
                <MobiCouponPage />
              </Suspense>
            }
          ></Route>

          <Route
            path="/broker-signup"
            element={
              <Suspense fallback={<>...</>}>
                <BrokerSignUpPage />
              </Suspense>
            }
          ></Route>

          <Route
            path="/broker"
            element={
              <Suspense fallback={<>...</>}>
                <BrokerPage />
              </Suspense>
            }
          ></Route>

          <Route
            path="/feedback"
            element={
              <Suspense fallback={<>...</>}>
                <FeedbackPage />
              </Suspense>
            }
          ></Route>

          <Route
            path="/services"
            element={
              <Suspense fallback={<>...</>}>
                <Mservices />
              </Suspense>
            }
          ></Route>
          <Route
            path="/mobicoupons"
            element={
              <Suspense fallback={<>...</>}>
                <MobiCoupons />
              </Suspense>
            }
          ></Route>

          <Route
            path="/mobishop"
            element={
              <Suspense fallback={<>...</>}>
                <MobiShop />
              </Suspense>
            }
          ></Route>

          <Route
            path="/mobitv"
            element={
              <Suspense fallback={<>...</>}>
                <MobiTv />
              </Suspense>
            }
          ></Route>

          <Route
            path="/mobiyellow"
            element={
              <Suspense fallback={<>...</>}>
                <MobiYellow />
              </Suspense>
            }
          ></Route>

          <Route
            path="/mobiyellow-menu"
            element={
              <Suspense fallback={<>...</>}>
                <MobiYellowPage />
              </Suspense>
            }
          ></Route>

          <Route
            path="/accessbank"
            element={
              <Suspense fallback={<>...</>}>
                <AccessBankPage />
              </Suspense>
            }
          ></Route>

          <Route
            path="/bprbank"
            element={
              <Suspense fallback={<>...</>}>
                <BprBankPage />
              </Suspense>
            }
          ></Route>

          <Route
            path="/cogebank"
            element={
              <Suspense fallback={<>...</>}>
                <CogebankPage />
              </Suspense>
            }
          ></Route>

          <Route
            path="/equitybank"
            element={
              <Suspense fallback={<>...</>}>
                <EquityBankPage />
              </Suspense>
            }
          ></Route>

          <Route
            path="/gtbank"
            element={
              <Suspense fallback={<>...</>}>
                <GtBankPage />
              </Suspense>
            }
          ></Route>

          <Route
            path="/imbank"
            element={
              <Suspense fallback={<>...</>}>
                <ImBankPage />
              </Suspense>
            }
          ></Route>

          <Route
            path="/bnr-psp-license"
            element={
              <Suspense fallback={<>...</>}>
                <MobicashLicense />
              </Suspense>
            }
          ></Route>
          <Route
            path="/send-message"
            element={
              <Suspense fallback={<>...</>}>
                <WhatsappMessenger />
              </Suspense>
            }
          ></Route>
          <Route
            path="/payment-completion"
            element={
              <Suspense fallback={<>...</>}>
                <MerchantPaymentSuccess />
              </Suspense>
            }
          ></Route>

          <Route
            path="/merchant-payment"
            element={
              <Suspense fallback={<>...</>}>
                <MerchantInformation />
              </Suspense>
            }
          ></Route>

          <Route
            path="/marketplace"
            element={
              <Suspense fallback={<>...</>}>
                <MarketplacePage />
              </Suspense>
            }
          ></Route>

          <Route
            path="/about"
            element={
              <Suspense fallback={<>...</>}>
                <AboutPage />
              </Suspense>
            }
          ></Route>
          <Route
            path="/terms"
            element={
              <Suspense fallback={<>...</>}>
                <TermsPage />
              </Suspense>
            }
          ></Route>

          <Route
            path="/tariff"
            element={
              <Suspense fallback={<>...</>}>
                <TarrifPage />
              </Suspense>
            }
          ></Route>

          <Route
            path="/policy"
            element={
              <Suspense fallback={<>...</>}>
                <PolicyPage />
              </Suspense>
            }
          ></Route>

          <Route
            path="/fraud"
            element={
              <Suspense fallback={<>...</>}>
                <FraudPage />
              </Suspense>
            }
          ></Route>

          <Route
            path="/business"
            element={
              <Suspense fallback={<>...</>}>
                <BusinessPage />
              </Suspense>
            }
          ></Route>
          <Route
            path="/scanpay"
            element={
              <Suspense fallback={<>...</>}>
                <ScanPay />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/kashiresi"
            element={
              <Suspense fallback={<>...</>}>
                <KashiresiPage />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/internet-banking"
            element={
              <Suspense fallback={<>...</>}>
                <InternetBankingPage />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/debit"
            element={
              <Suspense fallback={<>...</>}>
                <DebitPayPage />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/mobitokens"
            element={
              <Suspense fallback={<>...</>}>
                <MobiTokenPage />{" "}
              </Suspense>
            }
          ></Route>

          <Route
            path="/buy-electricity"
            element={
              <Suspense fallback={<>...</>}>
                <ElectricityPage />{" "}
              </Suspense>
            }
          >
            {" "}
          </Route>
          <Route
            path="/pay-cbhi"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <CbhiPage />{" "}
              </Suspense>
            }
          >
            {" "}
          </Route>
          <Route
            path="/pay-rra"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <RraMenu />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/pay-ltss"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <LtssMenu />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/pay-rnit"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <RnitMenu />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/buy-airtime"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <MtnMenu />{" "}
              </Suspense>
            }
          ></Route>

          <Route
            path="/support"
            element={
              <Suspense fallback={<>...</>}>
                <SupportCenter />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/vdisposable"
            element={
              <Suspense fallback={<>...</>}>
                <DisposableCard />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/vfixpremium"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <FixPremiumCard />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/inotipay"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <InotiPayCard />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/inotiSend"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <InotiSendCard />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/debitpay"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <DebitPay />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/marketplace-url"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <Marketplace />{" "}
              </Suspense>
            }
          ></Route>

          <Route
            path="/vcard"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <VcardPage />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/vcheque"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <VchequePage />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/lifestyle"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <Lifestyle />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/gocashless"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <Cashless />
              </Suspense>
            }
          ></Route>
          <Route
            path="/visit-rwanda-offers"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <VisitRwanda />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/rwandair-offers"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <Rwandair />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/cleo-hotel-offers"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <CleoHotel />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/akagera-offers"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <Akagera />{" "}
              </Suspense>
            }
          ></Route>
          <Route
            path="/alerts"
            element={
              <Suspense fallback={<>...</>}>
                {" "}
                <AlertPage />{" "}
              </Suspense>
            }
          ></Route>
        </Routes>
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;
