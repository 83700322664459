import React, { useState, useRef } from "react";
import {
  Container,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  FavoriteIcon,
  CardMedia,
  CardActions,
  CardContent,
  makeStyles,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import QrReader from "react-qr-reader";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import searchMerchantQrCode from "../../api/emv/MerchantSearchController";
import QrBeepSound from "../../video/Barcode-scanner-beep-sound.mp3";
import { HeaderMenu } from "../header/HeaderMenu";
import { FooterMenu } from "../footer/FooterMenu";
import { LicenseFooter } from "../footer/LicenseFooter";
import { MobiswiftScannerHeader } from "./MobiswiftScannerHeader";

function MobiswiftScanner() {
  const audioPlayer = useRef(null);
  const navigate = useNavigate();
  //const emvqr=require('emvqr');
  const [searchMerchantDetailMode, setSearchMerchantDetailMode] =
    useState(true);
  const classes = useStyles();

  function playAudio() {
    audioPlayer.current.play();
  }

  const handleErrorWebCam = (error) => {
    //Scanning Error:
    //alert('Want to cancel camera actions!!!');
    //console.log("Scanning Errors:"+error);
  };

  const handleOnloadWebCam = (object) => {
    //Scanning Error:
    //console.log("Onload Cam:"+object);
  };

  const handleOnImageLoadWebCam = (object) => {
    //Scanning Error:
    //console.log("OnImageLoad:");
  };
  const goToHomePage = () => {
    navigate("/");
  };

  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  function urlPathnameFinder(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url;
  }

  const handleScanWebCam = async (result) => {
    if (result) {
      let referralCode = "";
      playAudio();

      toast.dismiss();
      const id = toast.loading("");
      if (isValidHttpUrl(result)) {
        const pname = urlPathnameFinder(result).pathname;
        const searchParamsUrl = urlPathnameFinder(result).searchParams + "";
        const idSearcher = searchParamsUrl.split("=");
        const pathString = pname + "";
        const pnameparts = pname.split("/");

        if (idSearcher !== null) {
          referralCode = idSearcher[1];
          //referralCode=idSearcher[1];
          console.log("SearchParams:" + referralCode);
          //const idparm=idSearcher[0];
          //const idValue=idparm;
        }

        //toast.update(id, {render: "Scan2Pay With MobiSwift...", type: "info", isLoading: false,closeButton: null});

        if (pathString === "/pay-rra") {
          toast.dismiss();
          toast.update(id, {
            render: "Pay RRA Tax With MobiSwift",
            type: "info",
            isLoading: false,
            closeButton: null,
          });

          setTimeout(() => navigate("/pay-rra"), 1000);
        } else if (pathString === "/pay-cbhi") {
          toast.dismiss();
          toast.update(id, {
            render: "Pay CBHI With MobiSwift",
            type: "info",
            isLoading: false,
            closeButton: null,
          });
          setTimeout(() => navigate("/pay-cbhi"), 1000);
        } else if (pathString === "/pay-rnit") {
          toast.dismiss();
          toast.update(id, {
            render: "Pay RNIT With MobiSwift",
            type: "info",
            isLoading: false,
            closeButton: null,
          });
          setTimeout(() => navigate("/pay-rnit"), 1000);
        } else if (pathString === "/pay-ltss") {
          console.log("Go To LTSS Service Page");
          toast.dismiss();
          toast.update(id, {
            render: "Pay LTSS With MobiSwift",
            type: "info",
            isLoading: false,
            closeButton: null,
          });

          setTimeout(() => navigate("/pay-ltss"), 1000);
        } else if (pathString === "/buy-airtime") {
          toast.dismiss();
          toast.update(id, {
            render: "Buy MTN With MobiSwift",
            type: "info",
            isLoading: false,
            closeButton: null,
          });

          setTimeout(() => navigate("/buy-airtime"), 1000);
        } else if (pathString === "/buy-electricity") {
          toast.dismiss();
          toast.update(id, {
            render: "Buy Electricity With MobiSwift",
            type: "info",
            isLoading: false,
            closeButton: null,
          });
          navigate("/buy-electricity");
        } else if (pathString === "/services") {
          toast.dismiss();
          toast.update(id, {
            render: "Your MobiSwift Services Menu On The Go",
            type: "info",
            isLoading: false,
            closeButton: null,
          });

          setTimeout(() => navigate("/services"), 1000);
        } else if (pathString === "/signup") {
          toast.dismiss();
          toast.update(id, {
            render: "Your MobiSwift Services Menu On The Go",
            type: "info",
            isLoading: false,
            closeButton: null,
          });
          //console.log("signup?id="+referralCode);
          setTimeout(() => navigate("/signup?id=" + referralCode), 1000);
        } else {
          toast.update(id, {
            render: "Invalid MobiSwift Payment QRcode. Please try again!",
            type: "warning",
            isLoading: false,
            closeButton: null,
          });
        }
      } else {
        if (result.length > 150 && result.length <= 162) {
          //result
          //toast.update(id, {render: "In-store Mobicash Merchant QR Code Based payment service is currently unavailable. ", type: "info", isLoading: false,closeButton: null});
          //console.log("Calling Merchant Details")
          //00020101021129360032e1043f0e7e2748a3b50285bba02cda99520410005802RW5916mobiswift@1808696006KIGALI64360002EN0116mobiswift@1808690206KIGALI5303646620711030006304585C
          searchMerchantDetails(result);
        } else {
          toast.update(id, {
            render: "Invalid QR Code Scanning.Please try again to scan",
            type: "warning",
            isLoading: false,
            closeButton: null,
          });
        }
      }
    }
  };

  async function searchMerchantDetails(scanResultWebCam) {
    //e.preventDefault();
    if (scanResultWebCam != null) {
      //navigate('/merchant-payment',{state:scanResultWebCam});
      //setLoadingCounter(loadingCounter+1);
      // console.log("Logging QR String #")
      //await searchMerchantQrCodeDetails();

      //toast.dismiss(); // i need to hide all toast notification
      toast.dismiss();
      const id = toast.loading("Processing QR code identification..");

      //if button enabled with JS hack

      /*const v1 = USER_REGEX.test(user);
  const v2 = PWD_REGEX.test(pwd);
  
  if (!v1 || !v2) {
  setErrMsg("Invalid Entry");
  return;
  }*/

      try {
        //-----------Mock User Data----------------

        const sampleCbhiNidRequestPayload = {
          qrcodeString: scanResultWebCam,
        };
        //console.log("QR Code:"+scanResultWebCam);

        //console.log("Status Before Endpoint Calls:");
        const response = await searchMerchantQrCode(
          sampleCbhiNidRequestPayload
        );
        //const responseData=await response.json();
        setTimeout(
          () => {
            //console.log("Returned Data"+response.responseCode)
            //if(response!=null){console.log("Returned Data Elemets:"+response.responseCode)}
            if (response.responseCode === "100") {
              setSearchMerchantDetailMode(false);

              //console.log("Merchant Details:", response);

              /*setHeadHouseholdName(response.headHouseHoldNames);
     setHouseholdCategory(response.houseHoldCategory);
     setTotalHouseholdMembers(response.totalHouseHoldMembers);
     setHouseholdCategory(response.houseHoldCategory);
     setTotalHouseholdMembers(response.totalHouseHoldMembers);
     setTotalAmount(response.totalAmount);
     setTotalPaidAmount(response.totalPaidAmount);*/

              //setShowMoreFields(true);
              //setSuccess(true);
              //setStarterMode(false);
              //setSuccessMsg(response.responseStatus);
              //setSuccessMsg("");

              //toast.update(id, {render: "Merchant Information...", type: "success", isLoading: false,closeButton: null});
              //successRef.current.focus();
              //setShowContinueButton(false);
              //setShowPayButton(true);
              toast.dismiss();
              navigate("/merchant-payment", { state: response });
              //toast.dismiss();

              //i need to hide all toast notification
              //console.log("Loaded NID Data:", loadedNidData);
              //setOpenModal(true)
              //setSuccess(true);
              //setStarterMode(false);

              //setEmail('');
              //setPhone('');
              //setUsername('');
            } else {
              // setErrMsg(response.responseStatus);
              //setStarterMode(false);
              //console.log("CBHI NID Look-Up Status:", response);
              //console.log("Dates are not equals:");
              toast.update(id, {
                render: response.responseDescription,
                type: "info",
                isLoading: false,
                closeButton: null,
              });

              //setErrMsg("Please enter your date of birth as indicated on your National ID card.");

              //setStarterMode(false);
              //console.log("Failure Status - NID Look-Up:", response.data.responseCode);
              //toast.error('Failed To Find NID Information:', {position: toast.POSITION.TOP_RIGHT});
              //console.log(toastId);

              //errRef.current.focus();

              //setEmail('');
              //setPhone('');
              //setUsername('');
            }
          },

          2200
        );
        //setTimeout(() => { console.log("Returned Data"+response)}, 3000)}

        //toast.update(id, {render: "Data Loaded Successfully"+responseData, type: "success", isLoading: false,closeButton: null});
        //console.log("Status After Endpoint Calls:"+response.json());

        //
      } catch (err) {
        //console.log("Error Handling QR Code:"+err?.response);
        // setErrMsg(err.response);

        //console.log("Server Response Error-Client:", err.data);
        //setStarterMode(false);
        toast.update(id, {
          render:
            "Dear customer we are unable to process your request now. Try again later.",
          type: "info",
          isLoading: false,
          closeButton: null,
        });

        //setEmail('');
        //setPhone('');
        //setUsername('');

        //const myModalEl = document.getElementById('rssbCbhi');
        //s myModalEl.show();
        if (!err?.response) {
          //setErrMsg('No Server Response');
          //toast.update(id, {render:"Dear customer we are unable to process your request now. Try again later.", type: "info", isLoading: false,closeButton: null});
        } else if (err.response?.status === 409) {
          //setErrMsg('Username Taken');
          //toast.update(id, {render:"Dear customer we are unable to process your request now. Try again later.", type: "info", isLoading: false,closeButton: null});
        } else {
          //setErrMsg('Registration Failed')
          //toast.update(id, {render:"Dear customer we are unable to process your request now. Try again later.", type: "info", isLoading: false,closeButton: null});
        }
        //errRef.current.focus();
        //errRef.current.focus();
      }
    }
  }

  return (
    <div>
      <MobiswiftScannerHeader />
      <div class="body-content">
        <div id="main-wrapper">
          <div class="content">
            <div class="container">
              <div class="authentication-form pb-15">
                <form action="#">
                  <div class="form-group pb-10">
                    <label></label>
                    <Container className={classes.conatiner}>
                      <div style={{ justifyItems: "center" }}>
                        <h6 className={classes.title}>
                          {" "}
                          {"QR CODE SCAN TO PAY "}
                        </h6>
                        <h6 style={{ fontSize: 10 }}>
                          To make a QR code payment, you have to scan the code
                          displayed by merchants:
                          <ul>
                            <li>On QR Code stand or Mobile phone in-store</li>
                            <li>On TV Live Stream Shopping</li>
                            <li>On Online purchase</li>
                          </ul>
                        </h6>
                        <hr style={{ color: "red", size: 20 }} width="100%" />
                      </div>
                      <Card
                        style={{
                          minHeight: "75vh",
                          background: `url("assets/images/qrbg.jpeg")`,
                        }}
                      >
                        <CardContent
                          style={{
                            justifyItems: "center",
                            marginTop: 50,
                            paddingLeft: 75,
                            paddingRight: 50,
                            fontSize: 8,
                          }}
                        >
                          <Grid
                            container
                            spacing={3}
                            direction="column"
                            alignItems="center"
                            justify="center"
                          >
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              {/*<h6 style={{justifyItems: 'center',color:"red"}}>QR CODE SCAN TO PAY</h6>*/}

                              <div class="cornersqr">
                                <div class="topqr leftqr"></div>
                                <div class="topqr rightqr"></div>
                                <div class="bottomqr rightqr"></div>
                                <div class="bottomqr leftqr"></div>
                                <QrReader
                                  delay={1000}
                                  onError={handleErrorWebCam}
                                  onScan={handleScanWebCam}
                                  onLoad={handleOnloadWebCam}
                                  onImageLoad={handleOnImageLoadWebCam}
                                  showViewFinder={false}
                                  resolution={600}
                                  style={{ justifyItems: "center" }}
                                />
                              </div>

                              <h6></h6>
                            </Grid>
                            <CardActions style={{ justifyItems: "center" }}>
                              <Button size="small" onClick={goToHomePage}>
                                Cancel
                              </Button>
                              {/* <Button size="small">Continue</Button>*/}
                            </CardActions>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Container>
                  </div>
                </form>
                .
              </div>
            </div>
          </div>

          <FooterMenu />

          <audio ref={audioPlayer} src={QrBeepSound} />
          <div class="scroll-top" id="scrolltop">
            <div class="scroll-top-inner">
              <i class="icofont-long-arrow-up"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const addingQrBorderStyle = {
  background: `url("../images/background/qrscanner-win.png)`,
};

const qrBorderStyle = {
  border: "5px solid rgba(255,95,0,1)",
};

const qrBorderStyle_l2 = {
  border: "5px solid rgba(37, 38, 51,1)",
};

const qrInnerBorderStyle = {
  border: "5px solid rgba(255,95,0,1)",
  width: "100%",
  borderRadius: 13,
  backgroundColor: "#f1f5f6",
};

const borderGridAlign = {
  justifyItems: "center",
};

const addingBackground = {
  bgcolor: "#ff900",
  background: `url("../images/background/qrscanner-win.png")`,
};

const useStyles = makeStyles((theme) => ({
  conatiner: {
    marginTop: 2,
    border: 12,
    borderRadius: 10,
    marginBottom: 2,
  },
  title: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    background: "#fffff",
    color: "#ff6a00",
    textAlign: "center",
    padding: 5,
    borderRadius: 5,
  },
  btn: {
    marginTop: 2,
    marginBottom: 20,
  },
}));
export default MobiswiftScanner;
