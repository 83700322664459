import React from "react";
import { Link } from "react-router-dom";
import { Card, Paper, makeStyles, Grid } from "@material-ui/core";
import pmtScanBg from "../../images/phase2/iconscap2pay.png";
import pmtCardbg from "../../images/phase2/iconmobitokens.png";
import serviceIconImg from "../../images/background/serviceicon.png";
import joinUsIc from "../../images/background/join-us-ic.jpeg";
import newsIcon2 from "../../images/background/newsicon-n2.png";
import chatMenuImg from "../../images/phase3/livechat.png";
import ticketSupImg from "../../images/phase4/ticket.png";
import feedbacksupImg from "../../images/phase3/feedbackicon.png";
import faqsupsupImg from "../../images/phase4/faq.png";
import debitPayButton from "../../images/phase2/iconkashiresi.png";
import kashiresiButton from "../../images/phase2/icondebipay.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

export function HomePageMenu() {
  const classes = useStyles();

  return (
    <>
      <h6>
        <i class="flaticon-right-arrow" style={{ fontSize: 12 }}>
          {" "}
          Payment Zone
        </i>
      </h6>
      <Paper
        elevation={0}
        variant="outlined"
        sx={{
          p: 2,
          margin: "auto",
          maxWidth: 500,
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        }}
        className={classes.root}
      >
        <div class="container">
          <div class="row">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
            >
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <div class="col-sm-8 col-md-6 col-lg-5 mb-2 mb-lg-0">
                  <div class="bg-light shadow-sm-0 rounded p-0 text-center">
                    <Link to="/scanpay">
                      <Card elevation={5}>
                        {" "}
                        <span class="d-block text-10 text-primary mt-1 mb-1">
                          <i>
                            <LazyLoadImage src={pmtScanBg} alt="..." />
                          </i>
                          {/*<h3 class="text-body" style={{fontSize:10}}><b>Scan2Pay</b></h3> */}
                        </span>
                      </Card>
                    </Link>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <div class="col-sm-8 col-md-6 col-lg-5 mb-2 mb-lg-0">
                  <div class="bg-light shadow-sm-0 rounded p-0 text-center">
                    <Link to="/mobitokens">
                      <Card elevation={5}>
                        {" "}
                        <span class="d-block text-10 text-primary mt-1 mb-1">
                          <i>
                            <LazyLoadImage src={pmtCardbg} alt="..." />
                          </i>

                          {/*<h3 class="text-body" style={{fontSize:10}}><b>MobiTokens</b></h3>*/}
                        </span>
                      </Card>

                      {/*<h3 class="text-body text-1">VCard</h3>*/}
                    </Link>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <div class="col-sm-8 col-md-6 col-lg-5 mb-2 mb-lg-0">
                  <div class="bg-light shadow-sm-0 rounded p-0  text-center">
                    <Link to="/kashiresi">
                      <Card elevation={5}>
                        <span class="d-block text-10 text-primary mt-1 mb-1">
                          <i>
                            <LazyLoadImage src={debitPayButton} alt="..." />
                          </i>

                          {/*<h3 class="text-body" style={{fontSize:10}}><b>KashiResi</b></h3>*/}
                        </span>
                      </Card>
                    </Link>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <div class="col-sm-8 col-md-6 col-lg-5">
                  <div class="bg-light shadow-sm-0 rounded p-0  text-center">
                    <Link to="/debit">
                      <Card elevation={5}>
                        <span class="d-block text-10 text-primary mt-1 mb-1">
                          <i>
                            <LazyLoadImage src={kashiresiButton} alt="..." />
                          </i>
                          {/* <h6 class="text-body" style={{fontSize:10}}><b>DebiPay</b></h6>*/}
                        </span>
                      </Card>
                    </Link>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Paper>
      <h6>
        <i class="flaticon-right-arrow" style={{ fontSize: 12 }}>
          {" "}
          Service Zone
        </i>
      </h6>
      <Paper
        elevation={0}
        variant="outlined"
        sx={{
          p: 2,
          margin: "auto",
          maxWidth: 500,
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        }}
        className={classes.root}
      >
        <div class="container">
          <div class="row">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div class="col-sm-12 col-lg-12 mb-2 mb-lg-0">
                  <div class="bg-light shadow-sm-0 rounded p-0 text-center">
                    <Link to="/services">
                      <Card elevation={5}>
                        {" "}
                        <span class="d-block text-10 text-primary mt-1 mb-1">
                          <i>
                            <LazyLoadImage src={serviceIconImg} alt="..." />
                          </i>
                        </span>
                      </Card>
                    </Link>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Paper>

      <h6>
        <i class="flaticon-right-arrow" style={{ fontSize: 12 }}>
          {" "}
          Membership Zone
        </i>
      </h6>
      <Paper
        elevation={0}
        variant="outlined"
        sx={{
          p: 2,
          margin: "auto",
          maxWidth: 500,
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        }}
        className={classes.root}
      >
        <div class="container">
          <div class="row">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div class="col-sm-12 col-lg-12 mb-2 mb-lg-0">
                  <div class="bg-light shadow-sm-0 rounded p-0 text-center">
                    <Link to="/signup">
                      <Card elevation={5}>
                        {" "}
                        <span class="d-block text-10 text-primary mt-1 mb-1">
                          <i>
                            <LazyLoadImage src={joinUsIc} alt="..." />
                          </i>
                        </span>
                      </Card>
                    </Link>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Paper>

      <h6>
        <i class="flaticon-right-arrow" style={{ fontSize: 12 }}>
          {" "}
          Communication Zone
        </i>
      </h6>

      <Paper
        elevation={0}
        variant="outlined"
        sx={{
          p: 2,
          margin: "auto",
          maxWidth: 500,
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        }}
        className={classes.root}
      >
        <div class="container">
          <div class="row">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div class="col-sm-12 col-lg-12 mb-2 mb-lg-0">
                  <div class="bg-light shadow-sm-0 rounded p-0 text-center">
                    <Link to="/alerts">
                      <Card elevation={5}>
                        {" "}
                        <span class="d-block text-10 text-primary mt-1 mb-1">
                          <i>
                            <LazyLoadImage src={newsIcon2} alt="..." />
                          </i>
                        </span>
                      </Card>
                    </Link>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Paper>
      <h6>
        <i class="flaticon-right-arrow" style={{ fontSize: 12 }}>
          {" "}
          Support Zone
        </i>
      </h6>

      <Paper
        elevation={0}
        variant="outlined"
        sx={{
          p: 2,
          margin: "auto",
          maxWidth: 500,
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        }}
        className={classes.root}
      >
        <div class="container">
          <div class="row">
            <h6 style={{ color: "#f76a07", textAlign: "right", fontSize: 10 }}>
              <b>We are ready to help you 24/7/365</b>
            </h6>
            <Grid container spacing={1}>
              <Grid item xs={3} sm={3}>
                <div class="col-sm-8 col-lg-6 mb-2 mb-lg-0">
                  <div class="bg-light shadow-sm-0 rounded p-0 text-center">
                    <Link to="/support-center">
                      <Card elevation={5}>
                        {" "}
                        <span class="d-block text-10 text-primary mt-1 mb-1">
                          <i>
                            <LazyLoadImage src={chatMenuImg} alt="..." />
                          </i>
                        </span>
                      </Card>
                      <h3 class="text-body text-1"> </h3>
                    </Link>
                  </div>
                </div>
              </Grid>

              <Grid item xs={3} sm={3}>
                <div class="col-sm-8 col-lg-6 mb-2 mb-lg-0">
                  <div class="bg-light shadow-sm-0 rounded p-0 text-center">
                    <a href="/send-message">
                      <Card elevation={5}>
                        {" "}
                        <span class="d-block text-10 text-primary mt-1 mb-1">
                          <i>
                            <LazyLoadImage src={ticketSupImg} alt="..." />
                          </i>
                        </span>
                      </Card>

                      {/*<h3 class="text-body text-1">Ticket</h3>*/}
                    </a>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} sm={3}>
                <div class="col-sm-8 col-lg-6">
                  <div class="bg-light shadow-sm-0 rounded p-0 text-center">
                    <Link to="/feedback">
                      <Card elevation={5}>
                        <span class="d-block text-10 text-primary mt-1 mb-1">
                          <i>
                            <LazyLoadImage src={feedbacksupImg} alt="..." />
                          </i>
                        </span>
                      </Card>

                      {/*<h3 class="text-body text-1">Message</h3>*/}
                    </Link>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} sm={3}>
                <div class="col-sm-8 col-lg-6">
                  <div class="bg-light shadow-sm-0 rounded p-0 text-center">
                    <Link to="/support">
                      <Card elevation={5}>
                        <span class="d-block text-10 text-primary mt-1 mb-1">
                          <i>
                            <LazyLoadImage src={faqsupsupImg} alt="..." />
                          </i>
                        </span>
                      </Card>

                      {/*<h3 class="text-body text-1">FAQ</h3>*/}
                    </Link>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Paper>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    borderColor: "#ff9900",
    padding: 2,
    marginTop: 5,
    marginLeft: 5,
    marginRightt: 5,
  },
}));
