import React from "react";
import { Link } from "react-router-dom";

import homeIc from "../../images/phase3/bhome.png";
import serviceIc from "../../images/phase3/bservices.png";
import marketIc from "../../images/phase4/marketplace.png";
import partnershipIc from "../../images/phase3/bpartnership.png";
import moreAppIc from "../../images/phase3/bmoreapps.png";
import { LicenseFooter } from "./LicenseFooter";

export function FooterMenu() {
  return (
    <div>
      <section class="section">
        <div>
          <LicenseFooter />
        </div>

        <div className="app-navbar">
          <div className="container">
            <div className="navbar-content ">
              <div class="navbar-content-item">
                <Link to="/" class="active">
                  {/*<i class="flaticon-house"></i>*/}
                  <i>
                    {" "}
                    <img src={homeIc} width={25} height={25} />
                  </i>
                  <b>Home</b>
                </Link>
              </div>
              <div class="navbar-content-item">
                <Link to="/services">
                  <i>
                    {" "}
                    <img src={serviceIc} width={25} height={25} />
                  </i>
                  {/*<i class="flaticon-menu-1"></i>*/}
                  <b>Services</b>
                </Link>
              </div>
              <div class="navbar-content-item">
                <Link to="/marketplace">
                  <i>
                    <img src={marketIc} width={25} height={25} />
                  </i>
                  {/*<i class="flaticon-menu-1"></i>*/}
                  <b>MarketPlace</b>
                </Link>
              </div>
              <div class="navbar-content-item">
                <Link to="/broker">
                  {/*<i class="flaticon-menu-1"></i>*/}
                  <i>
                    <img src={partnershipIc} width={25} height={25} />
                  </i>
                  <b>Partnership</b>
                </Link>
              </div>
              <div class="navbar-content-item">
                <Link to="#">
                  {/* <i class="flaticon-settings"></i>8*/}
                  <i>
                    {" "}
                    <img src={moreAppIc} width={25} height={25} />
                  </i>
                  <b>MoreApps</b>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
