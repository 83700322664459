import React from "react";
import { HeaderMenu } from "../header/HeaderMenu";
import { FooterMenu } from "../footer/FooterMenu";
import { LicenseFooter } from "../footer/LicenseFooter";

export default function LinkWrapper(props) {
  const url = "https://tawk.to/chat/639c5c56daff0e1306dcf6cc/1gkdchd34";

  return (
    <div>
      <div id="main-wrapper">
        <HeaderMenu />

        <div id="content">
          <section class="section py-1 my-1 py-sm-2 my-sm-2">
            <iframe src={url} style={{ width: "100%", height: "400px" }} />
          </section>
        </div>
      </div>

      <FooterMenu />
    </div>
  );
}
