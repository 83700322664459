import React, { useRef, useState, useMemo, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import MobInvoice from "./MobInvoice";
import {
  Container,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  FavoriteIcon,
  Paper,
  ListItemIcon,
  CardMedia,
  MenuItem,
  CardActions,
  CardContent,
  makeStyles,
  Grid,
  TextField,
} from "@material-ui/core";
//import 'hammerjs';
import { PDFExport } from "@progress/kendo-react-pdf";
import printImg from "../../images/background/printerimg2.png";
import pdfIconImg from "../../images/background/pdficon.png";
import emailValImg from "../../images/background/emailval.png";
import { FooterMenu } from "../footer/FooterMenu";
import servicesImg from "../../images/phase4/services.png";

const ReceiptPreview = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("Receipt Preview Data:" + state.receiptPaymentMethod);
  const pdfExportComponent = useRef(null);
  const [layoutSelection, setLayoutSelection] = useState({
    text: "A4",
    value: "size-a4",
  });

  const ddData = [
    { text: "A4", value: "size-a4" },
    { text: "Letter", value: "size-letter" },
    { text: "Executive", value: "size-executive" },
  ];

  const handleExportWithComponent = (event) => {
    pdfExportComponent.current.save();
  };

  const updatePageLayout = (event) => {
    setLayoutSelection(event.target.value);
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div id="main-wrapper">
      <div id="content">
        <Paper
          elevation={0}
          sx={{
            p: 2,
            margin: "auto",
            maxWidth: 500,
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "#1A2027" : "#fff",
          }}
          className={classes.root}
        >
          <div class="container">
            <div class="row">
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
              >
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <div class="col-sm-8 col-md-6 col-lg-5 mb-2 mb-lg-0">
                    <div class="bg-light shadow-sm-0 rounded p-0 text-center">
                      <Card elevation={1}>
                        {" "}
                        <span
                          class="d-block text-10 text-primary mt-1 mb-1"
                          onClick={handleExportWithComponent}
                        >
                          <i>
                            <img src={pdfIconImg} />
                          </i>
                          <h3 class="text-body" style={{ fontSize: 10 }}>
                            <b>Export</b>
                          </h3>
                        </span>
                      </Card>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <div class="col-sm-8 col-md-6 col-lg-5 mb-2 mb-lg-0">
                    <div class="bg-light shadow-sm-0 rounded p-0 text-center">
                      <Card elevation={1}>
                        {" "}
                        <span
                          class="d-block text-10 text-primary mt-1 mb-1"
                          onClick={handlePrint}
                        >
                          <i>
                            <img src={printImg} />
                          </i>

                          <h3 class="text-body" style={{ fontSize: 10 }}>
                            <b>Print</b>
                          </h3>
                        </span>
                      </Card>

                      {/*<h3 class="text-body text-1">VCard</h3>*/}
                    </div>
                  </div>
                </Grid>

                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <div class="col-sm-8 col-md-6 col-lg-5">
                    <div class="bg-light shadow-sm-0 rounded p-0  text-center">
                      <Card elevation={1}>
                        <span class="d-block text-10 text-primary mt-1 mb-1">
                          <i>
                            <img src={emailValImg} />
                          </i>
                          <h6 class="text-body" style={{ fontSize: 10 }}>
                            <b>Validate E-mail</b>
                          </h6>
                        </span>
                      </Card>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <div class="col-sm-8 col-md-6 col-lg-5 mb-2 mb-lg-0">
                    <div class="bg-light shadow-sm-0 rounded p-0  text-center">
                      <Link to="/services">
                        <Card elevation={1}>
                          <span class="d-block text-10 text-primary mt-1 mb-1">
                            <i>
                              <img src={servicesImg} />
                            </i>
                            <h3 class="text-body" style={{ fontSize: 10 }}>
                              <b>Services</b>
                            </h3>
                          </span>
                        </Card>
                      </Link>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Paper>

        <PDFExport ref={pdfExportComponent}>
          <MobInvoice
            ref={componentRef}
            accessBankTransactionId={state.accessBankTransactionId}
            remarks={state.remarks}
            mobicoreTransactionId={state.mobicoreTransactionId}
            momoTransactionId={state.momoTransactionId}
            userEmail={state.userEmail}
            userFeeAmount={state.userFeeAmount}
            receiptPayerName={state.receiptPayerName}
            receiptPayerPhone={state.receiptPayerPhone}
            receiptService={state.receiptService}
            receiptAmountpaid={state.receiptAmountpaid}
            receiptPaymentYear={state.receiptPaymentYear}
            receiptTotalPremium={state.receiptTotalPremium}
            receiptHouseHoldCategory={state.receiptHouseHoldCategory}
            receiptRetcode={state.receiptRetcode}
            receiptDatetime={state.receiptDatetime}
            receiptHouseHoldNID={state.receiptHouseHoldNID}
            receiptServiceAmount={state.receiptServiceAmount}
            receiptHouseholdMemberNumber={state.receiptHouseholdMemberNumber}
            receiptMeterNo={state.receiptMeterNo}
            receiptElectrcityNo={state.receiptElectrcityNo}
            receiptElectrcityCustomerName={state.receiptElectrcityCustomerName}
            receiptElectricityToken={state.receiptElectricityToken}
            receiptElectricityUnits={state.receiptElectricityUnits}
            receiptElectricityAmountPaid={state.receiptElectricityAmountPaid}
            receiptElectricityTaxRate={state.receiptElectricityTaxRate}
            receiptElectricityTaxDesc={state.receiptElectricityTaxDesc}
            receiptServiceId={state.receiptServiceId}
            receiptPaymentMethod={state.receiptPaymentMethod}
          />
        </PDFExport>
      </div>

      <FooterMenu />
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 10,
    borderColor: "#ff9900",
    padding: 2,
    marginTop: 5,
    marginLeft: 5,
    marginRightt: 5,
  },
}));
export default ReceiptPreview;
