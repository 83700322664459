import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { QRCode } from "react-qrcode-logo";
import mobicashlogoImg from "../../images/background/mobicashlogo.png";
import logoImg2 from "../../images/background/logo.png";

const MobInvoice = React.forwardRef((props, ref) => {
  //console.log("Payer Name:"+props.remarks);
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  /*
const receiptPayloadCbhi={
    
        "payerName":momoHoldername,
        "payerPhone":value,
        "service":"CBHI",
        "amountPaid":amount, 
        "paymentYear": yearOfPayment, 
        "houseHoldNID": nationalId, 
        "totalPremium": totalAmount, 
        "houseHoldCategory": householdCategory, 
        "householdMemberNumber": totalHouseholdMembers,
        "retcode": response.responseCode,
        "remarks": response.responseDescription,
        "tid": response.accessbankTransactionId,
        "transactionid": response.mobicoreTransactionId,
        "momotransactionid": response.momoTransactionNumber,
        "datetime": response.transactionDate,
        "email":"",
        "feeAmount":"",
        "serviceAmount":amount
      }

    accessBankTransactionId:accessBankTransactionId,
    remarks:remarks,
	mobicoreTransactionId:mobicoreTransactionId,
    momoTransactionId:momoTransactionId,
	userEmail:userEmail,
    userFeeAmount:userFeeAmount,
	receiptPayerName:receiptPayerName,
    receiptPayerPhone:receiptPayerPhone,
    receiptService:receiptService,
    receiptAmountpaid:receiptAmountpaid,
    receiptPaymentYear:receiptPaymentYear,
    receiptTotalPremium:receiptTotalPremium,
    receiptHouseHoldCategory:receiptHouseHoldCategory,
    receiptRetcode:receiptRetcode,
    receiptDatetime:receiptDatetime,
    receiptHouseHoldNID:receiptHouseHoldNID,
    receiptServiceAmount:receiptServiceAmount,
    receiptHouseholdMemberNumber:receiptHouseholdMemberNumber
*/

  return (
    <div id="main-wrapper">
      <div id="content">
        <div class="invoice-box" ref={ref}>
          <table cellpadding="0" cellspacing="0">
            <tr class="top">
              <td colspan="2">
                <table>
                  <tr>
                    <td class="title" style={{ textAlign: "center" }}>
                      <img src={mobicashlogoImg} width="150" height="40" />
                      {/*<hr style={{width:'100%',marginBottom:0,paddingBottom:0}}/>*/}
                    </td>

                    {/*	<td>
									Receipt #: 123<br />
									Date:{new Date().toLocaleString() + ""}<br />
									
	</td>*/}
                  </tr>
                </table>
              </td>
            </tr>
            {
              //Georgia, serif
              //Garamond
            }
            <tr
              class="information"
              style={{ marginTop: "0", fontFamily: "Garamond,serif" }}
            >
              <td colspan="2">
                <table>
                  <tr style={{ fontSize: 14 }}>
                    <th>
                      <div>
                        <b style={{ color: "#ff9900" }}>Mobicash Ltd</b>
                        {/*<div class="align-breaker"><br/></div>*/}
                        <br />
                        Address: KN 3 Road,8 <br />
                        Gasabo - Kigali
                        <br />
                        <a href="https://support.mobicash.rw">
                          support.mobicash.rw
                        </a>
                      </div>
                    </th>
                    <th>
                      <div class="client-side">
                        <span class="info-details">
                          <b style={{ color: "#ff9900" }}>MobiSwift Payment</b>
                          <br />
                          Date: {props.receiptDatetime} <br />
                          Receipt #:{props.accessBankTransactionId}
                          <br />
                          Currency:Rwf
                          <br />
                        </span>
                      </div>
                    </th>
                  </tr>
                </table>
              </td>
              <hr
                style={{ width: "100%", marginBottom: 0, paddingBottom: 0 }}
              />
            </tr>
            <tr class="heading" style={{ fontSize: 16 }}>
              <td>Payer Details</td>

              <td></td>
            </tr>
            <tr
              class="information"
              style={{ marginTop: "0", fontFamily: "Garamond,serif" }}
            >
              <td colspan="2">
                <table>
                  <tr style={{ fontSize: 12 }}>
                    <th>
                      <div>
                        Name: {props.receiptPayerName}
                        {/*<div class="align-breaker"><br/></div>*/}
                        <br />
                        NID: {props.receiptHouseHoldNID}
                        <br />
                        A/C:{props.receiptPayerPhone}
                      </div>
                    </th>
                    <th>
                      <div class="client-side">
                        <span class="info-details">
                          E-mail:{"-"}
                          <br />
                          Payment Type:Self-service
                          <br />
                          Payment Method:{props.receiptPaymentMethod}
                          <br />{" "}
                        </span>
                      </div>
                    </th>
                  </tr>
                </table>
              </td>
            </tr>
            {/*
				<tr class="heading">
					<td>Item(s)</td>

					<td></td>
				</tr>

				<tr class="details">
					<td>MTN Mobile Money</td>
					<td>1,075.00</td>
				</tr>
*/}
            <tr class="heading" style={{ fontSize: 16 }}>
              <td>Service Item(s)</td>
              <td>Amount</td>
            </tr>
            <tr
              class="item"
              style={{ fontSize: 16, fontFamily: "Garamond,serif" }}
            >
              <td>{props.receiptService}</td>

              <td>{numberWithCommas(props.receiptAmountpaid)}</td>
            </tr>
            <tr
              class="item last"
              style={{ fontSize: 14, fontFamily: "Garamond,serif" }}
            >
              <td>Service Fee</td>

              <td>
                {props.receiptServiceId === "6" ? (
                  <>{props.userFeeAmount}</>
                ) : (
                  <>{"0"}</>
                )}
              </td>
            </tr>
            <tr class="total" style={{ fontSize: 18 }}>
              <td>Total</td>

              <td>Rwf{numberWithCommas(props.receiptAmountpaid)}</td>
            </tr>
            {/*
receiptMeterNo:"", receiptElectrcityNo:"",receiptElectrcityCustomerName:"", receiptElectricityToken:"",receiptElectricityUnits:"",receiptElectricityAmountPaid:"",receiptElectricityTaxRate:"",receiptElectricityTaxDesc:"",receiptServiceId:"",
*/}
            {props.receiptServiceId === "1" ? (
              <>
                <tr class="heading" style={{ fontSize: 14 }}>
                  <td>Electricity meter details:</td>
                </tr>

                <tr
                  class="item"
                  style={{ fontSize: 12, fontFamily: "Garamond,serif" }}
                >
                  <td>Meter Number:{props.receiptMeterNo}</td>
                </tr>
                <tr
                  class="item"
                  style={{ fontSize: 12, fontFamily: "Garamond,serif" }}
                >
                  <td>Receipt Number:{props.receiptElectrcityNo}</td>
                </tr>
                <tr
                  class="item"
                  style={{ fontSize: 12, fontFamily: "Garamond,serif" }}
                >
                  <td>Customer Name:{props.receiptElectrcityCustomerName}</td>
                </tr>
                <tr
                  class="item"
                  style={{ fontSize: 12, fontFamily: "Garamond,serif" }}
                >
                  <td>Token:{props.receiptElectricityToken}</td>
                </tr>
                <tr
                  class="item"
                  style={{ fontSize: 12, fontFamily: "Garamond,serif" }}
                >
                  <td>Units:{props.receiptElectricityUnits}</td>
                </tr>
                <tr
                  class="item"
                  style={{ fontSize: 12, fontFamily: "Garamond,serif" }}
                >
                  <td>Amount Paid:{props.receiptElectricityAmountPaid}</td>
                </tr>
                <tr
                  class="item"
                  style={{ fontSize: 12, fontFamily: "Garamond,serif" }}
                >
                  <td>Tax:{props.receiptElectricityTaxRate}</td>
                </tr>
                <tr
                  class="item"
                  style={{ fontSize: 12, fontFamily: "Garamond,serif" }}
                >
                  <td>Tax Descrption:{props.receiptElectricityTaxDesc}</td>
                </tr>
              </>
            ) : (
              <></>
            )}
            {/*<tr class="item last" style={{fontSize:12,fontFamily:'Garamond,serif'}}>
					<td>Service Fee</td>

					
</tr>*/}
            <tr class="top">
              <td colspan="2">
                <table>
                  <tr>
                    <td class="title" style={{ textAlign: "center" }}>
                      <QRCode
                        value="12345#24556#45667#222455"
                        size={150}
                        logoImage={logoImg2}
                        logoWidth={20}
                        logoHeight={20}
                      />
                      <br />
                    </td>

                    {/*	<td>
									Receipt #: 123<br />
									Date:{new Date().toLocaleString() + ""}<br />
									
	</td>*/}
                  </tr>
                </table>
              </td>
            </tr>
            {
              //<tr class="receipt-footer"  style={{fontSize:10}}>
            }
            {/*<td colspan={12} style={{textAlign:'center'}}><u>FIDELITY ACCOUNT:</u> you've earned a discount of (1%).<br/> EARN & SAVE & REDEEM your saving as payment money.<br/><a href="#">OPEN ACCOUNT NOW</a></td>*/}
            {/*<td colspan={12} style={{textAlign:'center'}}><u></u>*/}{" "}
            {/*you've earned a discount of (1%).*/}{" "}
            {/* <br/>EARN & SAVE & REDEEM your saving as payment money.*/}
            {/*<br/><a href="#">OPEN ACCOUNT NOW</a></td>*/}
            {
              //</tr>
            }
            <tr class="details" style={{ fontSize: 16 }}>
              <td
                colspan={12}
                style={{ textAlign: "center", fontFamily: "Georgia, serif" }}
              >
                Thank you for Using Mobicash Services.
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
});

export default MobInvoice;
