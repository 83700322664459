import React from "react";
import { Link } from "react-router-dom";
import profileImg from "../../images/profile.png";
import logo from "../../images/background/logo.png";

export function MobiswiftScannerHeader() {
  return (
    <div>
      <div class="header-bg header-bg-1"></div>

      <div class="fixed-top">
        <div class="appbar-area sticky-black">
          <div class="container">
            <div class="appbar-container">
              <div class="appbar-item appbar-actions">
                <div class="appbar-action-item">
                  <Link href="/scanpay" class="back-page">
                    <i class="flaticon-left-arrow"></i>
                  </Link>
                </div>
                <div class="appbar-action-item">
                  <Link to="/scanpay">
                    <i>
                      <img src={logo} />
                    </i>
                  </Link>
                </div>
              </div>
              <div class="appbar-item appbar-page-title">
                <h3>Scan To Pay Service</h3>
              </div>
              <div class="appbar-item appbar-options">
                <div class="appbar-option-item appbar-option-notification">
                  <Link to="/notifications">
                    <i class="flaticon-bell"></i>
                  </Link>
                  <span class="option-badge"></span>
                </div>
                <div class="appbar-option-item appbar-option-profile">
                  <Link to="#">
                    <img src={profileImg} alt="profile" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
