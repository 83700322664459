import React, { useContext, useState, useMemo, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import $ from "jquery";
import { HeaderMenu } from "../header/HeaderMenu";
import { FooterMenu } from "../footer/FooterMenu";
import { LicenseFooter } from "../footer/LicenseFooter";

export default function MomoPaymentSuccess() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const showReceiptPreview = () => {
    // console.log("Receipt Data Printing:"+state.remarks);

    navigate("/receipt-preview", { state });
  };

  useEffect(() => {
    //alert($("#input").val())
    // Preloader
    $(window).on("load", function () {
      $('[data-loader="circle-side"]').fadeOut(); // will first fade out the loading animation
      $("#preloader").delay(333).fadeOut("slow"); // will fade out the white DIV that covers the website.
      $("body").delay(333);
    });

    // Dropdown show on hover
    $(
      ".primary-menu ul.navbar-nav li.dropdown, .login-signup ul.navbar-nav li.dropdown"
    ).on("mouseover", function () {
      if ($(window).width() > 991) {
        $(this).find("> .dropdown-menu").stop().slideDown("fast");
        $(this).bind("mouseleave", function () {
          $(this).find("> .dropdown-menu").stop().css("display", "none");
        });
      }
    });

    // When dropdown going off to the out of the screen.
    $(".primary-menu .dropdown-menu").each(function () {
      var menu = $("#header .header-row").offset();
      var dropdown = $(this).parent().offset();

      var i =
        dropdown.left +
        $(this).outerWidth() -
        (menu.left + $("#header .header-row").outerWidth());

      if (i > 0) {
        $(this).css("margin-left", "-" + (i + 5) + "px");
      }
    });

    // DropDown Arrow
    $(".primary-menu")
      .find("a.dropdown-toggle")
      .append($("<i />").addClass("arrow"));

    // Mobile Collapse Nav
    $(
      '.primary-menu .dropdown-toggle[href="#"], .primary-menu .dropdown-toggle[href!="#"] .arrow'
    ).on("click", function (e) {
      if ($(window).width() < 991) {
        e.preventDefault();
        var $parentli = $(this).closest("li");
        $parentli.siblings("li").find(".dropdown-menu:visible").slideUp();
        $parentli.find("> .dropdown-menu").stop().slideToggle();
        $parentli.siblings("li").find("a .arrow.open").toggleClass("open");
        $parentli.find("> a .arrow").toggleClass("open");
      }
    });

    // Mobile Menu Button Icon
    $(".navbar-toggler").on("click", function () {
      $(this).toggleClass("open");
    });
  });

  return (
    <div>
      <div id="main-wrapper">
        <HeaderMenu />

        <div class="bg-primary">
          <div class="container d-flex justify-content-center">
            <ul class="nav secondary-nav">
              <li class="nav-item">
                {" "}
                <a class="nav-link active" href="#">
                  Payment Status
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div id="content" class="py-4">
          <div class="container">
            <div class="row">
              <div class="col-md-8 col-lg-6 col-xl-5 mx-auto">
                <div class="bg-light shadow-sm rounded p-3 p-sm-4 mb-4">
                  <div class="text-center my-0">
                    <p class="text-center text-success text-15 line-height-1">
                      <i class="fas fa-check-circle"></i>
                    </p>
                    <p class="text-center text-success text-8 line-height-7">
                      Success!
                    </p>
                    <p class="text-center text-3">
                      Transaction Completed Successfully!
                    </p>
                  </div>
                  {/*<p class="text-center text-1 mb-2"><span class="text-2 font-weight-500"><u>FIDELITY ACCOUNT:</u></span> you've earned a discount of <span class="font-weight-500">  Rwf 75.</span><span class="text-2 font-weight-500">EARN-SAVE & REDEEM</span> your saving as payment money.  <a href="#"></a>.</p>*/}
                  {/*<a href="#" class="btn btn-primary btn-block">OPEN ACCOUNT NOW!</a>*/}
                  <button
                    class="btn btn-link btn-block"
                    onClick={showReceiptPreview}
                  >
                    <i class="fas fa-print"></i> View & Save & Print Receipt
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterMenu />
    </div>
  );
}
