/*
Author: gniyonge
MerchantSearchController- shall be controlling the way that a user interacts with MobiSwift App by controlling the flow control logic to determine what response to send back to a user when a user makes a network request
*/

//import axiosMtnAirtimePayemntGateway from "./Mtn-airtime";
import axios from "axios";
import { Buffer } from "buffer";

const base_server_url =
  "https://dev.mobivat.com/mobicore/emvqr/api/emvqr/decoder";

//nid-validation
// + '/auth/redirect'
const MTN_PAYMENT_URL = "/api/emvqr/decoder";
//const MTN_PAYMENT_URL_2="/mtn/api/v1/airtime/buyAirtime";

const searchMerchantQrCode = async (requestPayLoad) => {
  const adminUsername = "mobivat";
  const adminPassword = "1234";

  const serverResponse = {
    responseCode: "",
    responseDescription: "",
    responseStatus: "",
    countryCode: "",
    merchantCategoryCode: "",
    merchantCity: "",
    merchantName: "",
    mobicashQrcodeId: "",
    merchantGlobalUniqueIdentifier: "",
    payloadFormatIndicator: "",
    pointOfInitiationMethod: "",
    transactionCurrency: "",
    merchantId: "",
    profileImage: "",
    merchantUser: "",
    merchantEmail: "",
    merchantUsername: "",
    merchantPhone: "",
    businessCategoryCode: "",
    mobipassqQrCode: "",
    businessSizeType: "",
  };

  /*
   {
    "responseCode": "100",
    "communicationStatus": "SUCCESS",
    "codeDescription": "SUCCESS",
    "data": {
        "countryCode": "RW",
        "merchantCategoryCode": "1000",
        "merchantCity": "KIGALI",
        "merchantName": "mobiswift@180869",
        "mobicashQrcodeId": "180869",
        "merchantGlobalUniqueIdentifier": "[29360032e1043f0e7e2748a3b50285bba02cda99]",
        "payloadFormatIndicator": "01",
        "pointOfInitiationMethod": "11",
        "transactionCurrency": "646",
        "merchantId": null,
        "profileImage": "https://testbox.mobicash.rw/CoreBank/test_box/api/images/content/GSDFJbFJUbjq1BONPjIrwxjMCUya5ig6qmMgS4fVYov9djHX30sdir02qhj0NRMy_265x190.png",
        "merchantUser": "250_stores",
        "merchantEmail": "info.abc@abcsupermarket.com",
        "merchantUsername": "250Stores",
        "merchantPhone": "250Stores",
        "businessCategoryCode": "mcc_0742",
        "mobipassqQrCode": "180869",
        "businessSizeType": "small"
    },
    "responseDate": ""
}
   
   */
  const usernamePasswordBuffer = Buffer.from(
    adminUsername + ":" + adminPassword
  );
  const base64data = usernamePasswordBuffer.toString("base64");
  //console.log("Request Payload:"+requestPayLoad.qrcodeString);
  const config = {
    headers: { Authorization: `Basic ${base64data}` },
    withCredentials: true,
    params: { qrcodeString: requestPayLoad.qrcodeString },
  };
  //axios.get(API_SERVER + '/todos', { withCredentials: true })//axiosMerchantSearcherGateway
  axios
    .get(base_server_url, config)

    .then((response) => {
      //console.log("Server Response Body:", response.data.data);

      if (response.data.responseCode === "100") {
        //console.log("Accepted!:", response);
        //responseCode:'',responseDescription:'',status:'',countryCode: '',merchantCategoryCode: '',merchantCity: '',merchantName:'',mobicashQrcodeId:'',merchantGlobalUniqueIdentifier: '',payloadFormatIndicator: '',pointOfInitiationMethod: '',transactionCurrency: '',merchantId: '',profileImage: '',merchantUser: '',merchantEmail: '',merchantUsername: '',merchantPhone: '',businessCategoryCode: '',mobipassqQrCode:'',businessSizeType: ''

        serverResponse.responseDescription = response.data.codeDescription;
        serverResponse.responseStatus = response.data.communicationStatus;
        serverResponse.responseCode = response.data.responseCode;

        serverResponse.countryCode = response.data.data.countryCode;
        serverResponse.merchantCategoryCode =
          response.data.data.merchantCategoryCode;
        serverResponse.merchantCity = response.data.data.merchantCity;
        serverResponse.merchantName = response.data.data.merchantName;
        serverResponse.mobicashQrcodeId = response.data.data.mobicashQrcodeId;

        //serverResponse.merchantGlobalUniqueIdentifier=response.data.data.merchantGlobalUniqueIdentifier[0];
        serverResponse.payloadFormatIndicator =
          response.data.data.payloadFormatIndicator;
        serverResponse.pointOfInitiationMethod =
          response.data.data.pointOfInitiationMethod;
        serverResponse.transactionCurrency =
          response.data.data.transactionCurrency;

        serverResponse.profileImage = response.data.data.profileImage;
        serverResponse.merchantUser = response.data.data.merchantUser;
        serverResponse.merchantEmail = response.data.data.merchantEmail;
        serverResponse.merchantUsername = response.data.data.merchantUsername;
        serverResponse.merchantPhone = response.data.data.merchantPhone;
        serverResponse.businessCategoryCode =
          response.data.data.businessCategoryCode;
        serverResponse.mobipassqQrCode = response.data.data.mobipassqQrCode;
        serverResponse.businessSizeType = response.data.data.businessSizeType;

        console.log("Accepted Second:", serverResponse);
      } else {
        serverResponse.responseDescription = response.data.codeDescription;
        serverResponse.responseStatus = response.data.communicationStatus;
        serverResponse.responseCode = response.data.responseCode;
      }
    })
    .catch((err) => {
      if (err.code === "ERR_NETWORK") {
        //Error Network
        console.log("Please Check your Internet Network");
        serverResponse.responseDescription =
          "Dear customer we are unable to process your request now due to " +
          err.message +
          ".Please Check your internet and Try again.";
        serverResponse.responseStatus = err.code;
        serverResponse.responseCode = 1080;
      } else {
        //setErrMsg('Login Failed');
        serverResponse.responseDescription =
          "Dear customer we are unable to process your request now due to " +
          err.message +
          ".Please Try again.";
        serverResponse.responseStatus = err;
        serverResponse.responseCode = 1081;
      }
    });

  //return({ if(this.state.loginStatus){<home/>}else{}});

  return serverResponse;
};
export default searchMerchantQrCode;
//export {payCbhiContribution};
