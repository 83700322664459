import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import French from "../lang/fr.json";
import Kinyarwanda from "../lang/kiny.json";
import English from "../lang/en.json";

export const Context = React.createContext();

const local = navigator.language;
const selectedLang = "";

let lang;
if (local === "en") {
  lang = English;
} else {
  if (local === "fr") {
    lang = French;
  } else {
    if (local === "kin") {
      lang = Kinyarwanda;
    } else {
      lang = English;
    }
  }
}

const Wrapper = (props) => {
  const [locale, setLocale] = useState(local);

  const [messages, setMessages] = useState(lang);

  function selectLanguage(e) {
    const newLocale = e.target.value;
    setLocale(newLocale);
    if (newLocale === "en") {
      setMessages(English);
    } else {
      if (newLocale === "fr") {
        setMessages(French);
      } else {
        setMessages(Kinyarwanda);
      }
    }
  }

  function selectLanguage1() {
    console.log("Context of :");
    const newLocale = "kin";
    setLocale(newLocale);
    setMessages(Kinyarwanda);
  }

  function selectLanguage2() {
    console.log("Context of :");
    const newLocale = "en";
    setLocale(newLocale);
    setMessages(English);
  }

  function selectLanguage3() {
    console.log("Context of :");
    const newLocale = "fr";
    setLocale(newLocale);
    setMessages(French);
  }

  return (
    <Context.Provider
      value={{ locale, selectLanguage1, selectLanguage2, selectLanguage3 }}
    >
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default Wrapper;
