import React from "react";
import HomeCarousel from "../slider/HomeCarousel";
import { HomePageMenu } from "./HomePageMenu";
import { HeaderMenu } from "../header/HeaderMenu";
import { FooterMenu } from "../footer/FooterMenu";
import { LicenseFooter } from "../footer/LicenseFooter";

function PageHome() {
  return (
    <div>
      <div id="main-wrapper">
        <HeaderMenu />
        <div id="content">
          <div>
            <HomeCarousel />{" "}
          </div>

          <div>
            <HomePageMenu />
          </div>

          <div>
            <FooterMenu />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHome;
