import React from "react";

import ReactDOM from "react-dom/client";
import App from "./App";
import Wrapper from "./components/Wrapper";
import { CookiesProvider } from "react-cookie";
//import * as serviceWorker from './serviceWorker';
import reportWebVitals from "./reportWebVitals";

//const root = document.getElementById('root');
//import { render } from 'react-dom';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Wrapper>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </Wrapper>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below.
/*const configuration = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      if (window.confirm('New version available!  refresh to update your app?')) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }
    }
  }
}; 
serviceWorker.register(configuration);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
