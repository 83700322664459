import Carousel from "react-bootstrap/Carousel";

import sliderBgImg1 from "../../images/background/slider-bg-n1.jpg";
//import sliderBgImg2 from '../images/background/slider-bg-n2.jpg';
//import sliderBgImg3 from '../images/background/slider-bg-n3.jpg';
//import sliderBgImg4  from '../images/background/slider-bg-n4.jpg';
//import sliderBgImg5 from '../images/background/slider-bg-n5.jpg';
//import sliderBgImg6 from '../images/background/slider-bg-n6.jpg';
//import sliderBgImg7 from '../images/background/slider-bg-n7.jpg';
//import sliderBgImg8 from '../images/background/slider-bg-n8.jpg';

import { LazyLoadImage } from "react-lazy-load-image-component";

function DemoCarousel() {
  return (
    <Carousel variant="orange" fade style={{ borderRadius: 5 }}>
      <Carousel.Item>
        <LazyLoadImage
          src={sliderBgImg1}
          className="d-block w-100"
          alt="Loading..."
        />

        <Carousel.Caption class="aligner">
          {/*<button class="btn btn-outline-light"> READ MORE</button>*/}
          <p>
            <a href="/" class="aligners">
              <b>READ MORE</b>
            </a>
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={sliderBgImg1}
          alt="Loading Mobiswift..."
        />
        <Carousel.Caption class="aligner">
          <p>
            <a href="/" class="aligners">
              <b>READ MORE</b>
            </a>
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      {/*<Carousel.Item >
         <img
          className="d-block w-100"
          src={sliderBgImg3}
          alt="Loading Mobiswift..."
        />
        <Carousel.Caption class="aligner">
        <p><a href="/" class="aligners"><b>READ MORE</b></a></p>
          
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item >
        <img
          className="d-block w-100"
          src={sliderBgImg4}
          alt="Loading Mobiswift slider..."
        />
        <Carousel.Caption class="aligner">
        <p><a href="/" class="aligners"><b>READ MORE</b></a></p>
          
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item >
        <img
          className="d-block w-100"
          src={sliderBgImg5}
          alt="loading mobiswift slider 5"
        />
        <Carousel.Caption class="aligner">
        <p><a href="/" class="aligners"><b>READ MORE</b></a></p>
          
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item >
        <img
          className="d-block w-100"
          src={sliderBgImg6}
          alt="loading mobiswift slider 6"
        />
        <Carousel.Caption class="aligner">
        <p><a href="/" class="aligners"><b>READ MORE</b></a></p>
          
        
        </Carousel.Caption>
      </Carousel.Item>
     
    
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={sliderBgImg7}
          alt="loading mobiswift slider 7"
        />
        <Carousel.Caption class="aligner">
        <p><a href="/" class="aligners"><b>READ MORE</b></a></p>
        
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={sliderBgImg8}
          alt="loading mobiswift slider 8"
        />
        <Carousel.Caption class="aligner">
        <p><a href="/home" class="aligners"><b>READ MORE</b></a></p>
        
        </Carousel.Caption>
      </Carousel.Item>*/}
    </Carousel>
  );
}

export default DemoCarousel;
