import React, { useContext, useEffect } from "react";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { Context } from "../Wrapper";
import frenchLogo from "../../images/background/fre-curr.png";
import englishLogo from "../../images/background/eng-curr.png";
import kinyaLogo from "../../images/background/rda-curr.png";
import loginCircleImg from "../../images/ic_login_circle.jpeg";
import mainLogoImg from "../../images/logo/logo.jpeg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import $ from "jquery";

export function HeaderMenu() {
  const context = useContext(Context);

  const kinyLanguageChooser = (e) => {
    e.preventDefault();
    console.log("Choosing Kinyarwanda");
    context.selectLanguage1();
  };

  const englishLanguageChooser = (e) => {
    e.preventDefault();
    console.log("Choosing English");
    context.selectLanguage2();
  };

  const frenchLanguageChooser = (e) => {
    e.preventDefault();
    console.log("Choosing French");
    context.selectLanguage3();
  };

  useEffect(() => {
    $(window).on("load", function () {
      $('[data-loader="circle-side"]').fadeOut(); // will first fade out the loading animation
      $("#preloader").delay(333).fadeOut("slow"); // will fade out the white DIV that covers the website.
      $("body").delay(333);
    });

    // Dropdown show on hover
    $(
      ".primary-menu ul.navbar-nav li.dropdown, .login-signup ul.navbar-nav li.dropdown"
    ).on("mouseover", function () {
      if ($(window).width() > 991) {
        $(this).find("> .dropdown-menu").stop().slideDown("fast");
        $(this).bind("mouseleave", function () {
          $(this).find("> .dropdown-menu").stop().css("display", "none");
        });
      }
    });

    // When dropdown going off to the out of the screen.
    $(".primary-menu .dropdown-menu").each(function () {
      var menu = $("#header .header-row").offset();
      var dropdown = $(this).parent().offset();

      var i =
        dropdown.left +
        $(this).outerWidth() -
        (menu.left + $("#header .header-row").outerWidth());

      if (i > 0) {
        $(this).css("margin-left", "-" + (i + 5) + "px");
      }
    });

    // DropDown Arrow
    $(".primary-menu")
      .find("a.dropdown-toggle")
      .append($("<i />").addClass("arrow"));

    // Mobile Collapse Nav
    $(
      '.primary-menu .dropdown-toggle[href="#"], .primary-menu .dropdown-toggle[href!="#"] .arrow'
    ).on("click", function (e) {
      if ($(window).width() < 991) {
        e.preventDefault();
        var $parentli = $(this).closest("li");
        $parentli.siblings("li").find(".dropdown-menu:visible").slideUp();
        $parentli.find("> .dropdown-menu").stop().slideToggle();
        $parentli.siblings("li").find("a .arrow.open").toggleClass("open");
        $parentli.find("> a .arrow").toggleClass("open");
      }
    });

    // Mobile Menu Button Icon
    $(".navbar-toggler").on("click", function () {
      $(this).toggleClass("open");
    });
  });

  return (
    <>
      <header id="header">
        <div class="container">
          <div class="header-row">
            <div class="header-column justify-content-start">
              <div class="logo">
                {" "}
                <a class="d-flex" href="#" title="MobiSwift">
                  <LazyLoadImage
                    src={mainLogoImg}
                    width="250"
                    height="100"
                    alt="MobiSwift"
                  />
                </a>{" "}
              </div>
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#header-nav"
              >
                {" "}
                <span></span> <span></span> <span></span>{" "}
              </button>
              <nav class="primary-menu navbar navbar-expand-lg">
                <div id="header-nav" class="collapse navbar-collapse">
                  <ul class="navbar-nav mr-auto">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li class="dropdown dropdown-mega">
                      {" "}
                      <a href="/about">About</a>
                    </li>

                    <li>
                      <a href="/business">Business</a>
                    </li>
                    <li class="dropdown">
                      {" "}
                      <a class="dropdown-toggle" href="#">
                        Terms
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a class="dropdown-item" href="/terms">
                            Terms & Conditions
                          </a>
                        </li>
                        <li>
                          <a class="dropdown-item" href="/fraud">
                            Fraud Awareness
                          </a>
                        </li>
                      </ul>
                    </li>
                    {/* <li><a href="#">Business</a></li>*/}

                    <li>
                      <a href="/tariff">Tariff</a>
                    </li>
                    <li>
                      <a href="/support">Support</a>
                    </li>
                    <li class="dropdown">
                      {" "}
                      <a class="dropdown-toggle" href="#">
                        {context.locale === "fr" ? (
                          <>
                            <MenuItem>
                              <ListItemIcon>
                                <LazyLoadImage
                                  src={frenchLogo}
                                  width={40}
                                  height={40}
                                  alt="FR"
                                />
                              </ListItemIcon>
                              French
                            </MenuItem>
                          </>
                        ) : (
                          <>
                            {context.locale === "kin" ? (
                              <>
                                <MenuItem>
                                  <ListItemIcon>
                                    <LazyLoadImage
                                      src={kinyaLogo}
                                      width={40}
                                      height={40}
                                      alt="Kiny"
                                    />
                                  </ListItemIcon>
                                  Kinyarwanda
                                </MenuItem>
                              </>
                            ) : (
                              <>
                                <MenuItem>
                                  <ListItemIcon>
                                    <LazyLoadImage
                                      src={englishLogo}
                                      width={40}
                                      height={40}
                                      alt="EN"
                                    />
                                  </ListItemIcon>
                                  English
                                </MenuItem>
                              </>
                            )}
                          </>
                        )}
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={kinyLanguageChooser}
                          >
                            <MenuItem>
                              <ListItemIcon>
                                <LazyLoadImage
                                  src={kinyaLogo}
                                  width={40}
                                  height={40}
                                  alt="KI"
                                />
                              </ListItemIcon>
                              Kinyarwanda
                            </MenuItem>
                          </a>
                        </li>

                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={englishLanguageChooser}
                          >
                            <MenuItem>
                              <ListItemIcon>
                                <LazyLoadImage
                                  src={englishLogo}
                                  width={40}
                                  height={40}
                                  alt="EN"
                                />
                              </ListItemIcon>
                              English
                            </MenuItem>
                          </a>
                        </li>

                        <li>
                          <a
                            class="dropdown-item"
                            href="#"
                            onClick={frenchLanguageChooser}
                          >
                            <MenuItem>
                              <ListItemIcon>
                                <LazyLoadImage
                                  src={frenchLogo}
                                  width={40}
                                  height={40}
                                  alt="FR"
                                />
                              </ListItemIcon>
                              French
                            </MenuItem>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div class="header-column justify-content-end">
              <nav class="login-signup navbar navbar-expand">
                <ul class="navbar-nav">
                  <li>
                    <a href="/signin">
                      <LazyLoadImage
                        src={loginCircleImg}
                        width="50"
                        alt="..."
                      />
                    </a>{" "}
                  </li>
                  <li class="align-items-center h-auto ml-sm-3">
                    <a class="btn btn-primary d-none d-sm-block" href="/signup">
                      Sign Up
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
