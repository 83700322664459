import React from "react";
import { Link } from "react-router-dom";

export function LicenseFooter() {
  return (
    <div
      style={{
        fontSize: 12,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span>
        <br />
        Mobicash is a financial institution licensed by BNR as{" "}
        <a href="/bnr-psp-license">
          <u>PSP nr 16</u>
        </a>
      </span>
    </div>
  );
}
